html, body, p {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  min-height: 100vh;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom, #6EEEFF, #A850D8);
}

#root {
  display: flex;
  flex: 1;
  margin: 50px;
}

.container-fluid {
  display: flex;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.card {
  background-image: linear-gradient(to bottom, #6EEEFF, #A850D8);
  box-shadow: 0px 10px 8px #A850D8;
  border: 0;
  padding: 20px;
}

h1, h2, h3, h4, h5, h6 {
  color: #fdfeec;
  font-family: 'Carter One';
  font-weight: 700;
  text-shadow: 2px 3px 2px #a850d8;
}

a {
  color: white;
  transition: 0.3s;
}

a:hover {
  color: #ffdbfa;
  transition: 0.3s;
}

.thin {
  font-weight: 100;
}

h2 {
  font-size: 18px;
  font-weight: 700px;
}

.labels_container {
  margin-left: auto;
  margin-right: auto;
}

.labels_container > row {
  margin-left: auto;
  margin-right: auto;
}

.label_container {
  margin: 50px;
}

.label {
  /* align-items: center; */
  /* flex: 1; */
  height: 100px;
  justify-content: center;
  text-align: center;
}

.banner {
  min-height: 600px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 120px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-item {
  cursor: pointer;
}


#hey {
  font-size: 160px;
  margin-left: 80px;
}

#clayton {
  text-align: center;
}

#pic {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 50px -10px #A850D8;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 250px;
  z-index: 1;
}

#pic > img {
  border-radius: inherit;
}

#iconsContainer {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

@media (min-width: 1024px) {

  #pic {
    margin-right: -25px;
    width: 350px;
  }

  #iconsContainer {
    margin-left: auto;
    margin-right: auto;
    min-width: 600px;
  }
}


/* iPads (portrait) ----------- */
@media (max-width : 1024px) {


  #root {
    margin: 5px;
  }

  .container-fluid {
    padding: 40px;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  #hey {
    font-size: 96px;
    margin-left: auto;
    margin-right: auto;
  }

  .banner {
    min-height: 500px;
    padding: 40px;
    padding-top: 15vh;
    padding-bottom: 15vh;
  }

  .rec {
    margin-bottom: 80px;
    padding: 0px;
  }

  .rec-attr {
    align-items: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 40px;
  }

  .rec-text {
    margin: 25px;
    padding: 60px 10px 0px 10px;
  }

  #iconsContainer {
    justify-content: space-between;
  }
}

/* iPads (portrait) ----------- */
@media (max-width : 736px) {
  .container-fluid {
    padding: 15px;
  }

  #hey {
    font-size: 64px;
  }
}